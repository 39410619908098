import { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Box,
  Flex,
  Text,
  Theme,
  useDisclosure,
  useTheme,
  useToast,
} from '@chakra-ui/react';
import FAIcon from 'components/lib/FAIcon';
import JobsAddressModal from '../Jobs/Modal/JobAddress';
import {
  listClients,
  useDeleteClientLocationMutation,
} from 'store/client.slice';
import { useDispatch, useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { ATSTheme } from 'theme/theme';

const CustomOption = ({
  label,
  value,
  onEdit,
  innerProps,
}: {
  label: string;
  value: number;
  onEdit: () => void;
  innerProps: any;
}) => {
  const { colors } = useTheme<ATSTheme>();
  const dispatch = useDispatch();
  const toast = useToast({ isClosable: true, duration: 3000, position: 'top' });

  const [reqDeleteLocation] = useDeleteClientLocationMutation();

  const handleRemoveAddress = () => {
    const removeAddress = reqDeleteLocation({ id: value }).unwrap();
    toast.promise(removeAddress, {
      success: {
        title: 'Deleted Address!',
      },
      error: { title: 'Delete Address Error', description: 'Something wrong' },
      loading: { title: 'Deleting Address...' },
    });
    dispatch(listClients({ selected_client_location: [] }));
  };

  return (
    <Flex
      sx={{ justifyContent: 'space-between', p: '16px', cursor: 'pointer' }}
      _active={{
        backgroundColor: colors.primary[800],
        color: 'white',
      }}
      _hover={{
        backgroundColor: colors.primary[100],
        color: '#1B202B',
      }}
    >
      <Box sx={{ fontWeight: label === 'Create New Address' && 600 }}>
        <Text
          onClick={(e) => {
            innerProps.onClick(e);
          }}
        >
          {label}
        </Text>
      </Box>
      {typeof label === 'string' && (
        <Flex ml="20px" gap="15px" alignItems="center">
          <Box
            sx={{ cursor: 'pointer', color: 'primary.800' }}
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
          >
            <FAIcon iconName="pencil" />
          </Box>
          <Box
            sx={{ cursor: 'pointer', color: 'caution.800' }}
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveAddress();
            }}
          >
            <FAIcon iconName="trash" />
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

export default function AtsSelectAddress(FieldProps: any) {
  const { colors } = useTheme<ATSTheme>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const [hasCreate, setHasCreate] = useState(true);
  const options = FieldProps.options;

  const { selected_client_location, client_location_obj } = useSelector(
    (state: any) => state.clients
  );
  const [addressData, setAddressData] = useState({});
  const handleEditOption = (data: any) => {
    setAddressData(data.data || {});
    onOpen();
  };

  useEffect(() => {
    if (FieldProps.hasCreate !== undefined && FieldProps.hasCreate !== null) {
      setHasCreate(FieldProps.hasCreate);
    }
  }, [FieldProps.hasCreate]);

  useEffect(() => {
    if (Object.keys(selected_client_location).length > 0) {
      FieldProps?.form?.setFieldValue(FieldProps.field.name, {
        ...selected_client_location,
      });
      FieldProps?.onCreate?.({
        ...selected_client_location,
        ...client_location_obj,
      });
      dispatch(
        listClients({ selected_client: [], selected_client_location: [] })
      );
    }
  }, [selected_client_location?.value]);

  let filteredOptions: any[] = options
    ?.filter((option: any) =>
      option?.label?.toLowerCase().includes(inputValue.toLowerCase())
    )
    ?.slice(0, 5);
  const handleInputChange = (e: any) => {
    setInputValue(e);
  };

  useEffect(() => {
    return () => {
      dispatch(
        listClients({ selected_client: [], selected_client_location: [] })
      );
    };
  }, []);

  return (
    <>
      <CreatableSelect
        {...FieldProps.field}
        onChange={(option: any) => {
          if (option?.label !== 'Create New Address') {
            FieldProps.form.setFieldValue(FieldProps.field.name, option);
          }
          if (FieldProps.onChange) {
            FieldProps.onChange(option);
          }
        }}
        options={filteredOptions}
        placeholder={FieldProps.placeholder}
        defaultValue={FieldProps.defaultValue}
        components={{
          Option: ({ label, data, innerProps }) => {
            return (
              <div
                {...innerProps}
                onClick={(e) => {
                  // PURPOSE OF THIS ONCLICK:
                  // ONCLICK IN FORMATCREATELABEL DOES NOT FILL ENTIRE OPTION DIV
                  if (typeof label !== 'string' && hasCreate) {
                    handleEditOption(inputValue);
                  } else {
                    innerProps.onClick(e);
                  }
                }}
              >
                <CustomOption
                  label={label}
                  value={(data as any)?.value}
                  onEdit={() => handleEditOption(data)}
                  innerProps={innerProps}
                />
              </div>
            );
          },
        }}
        value={FieldProps.value}
        isClearable
        isValidNewOption={() => hasCreate}
        formatCreateLabel={(inputValue) => {
          return (
            <>
              {hasCreate && (
                <Box
                  w="100%"
                  h="inherit"
                  fontWeight="bold"
                  onClick={() => {
                    handleEditOption(inputValue);
                  }}
                >
                  {`Create New Address`}
                </Box>
              )}
            </>
          );
        }}
        onKeyDown={(e) => {
          if (hasCreate) {
            // User presses "Enter" and Input is a new value
            if (
              e.key === 'Enter' &&
              !(
                options.filter((option: any) =>
                  option?.label
                    ?.toLowerCase()
                    .includes(inputValue.toLowerCase())
                ).length > 0
              )
            ) {
              handleEditOption(inputValue);
            }
          }
        }}
        onInputChange={(e: any) => {
          if (FieldProps.onInputChange) {
            FieldProps.onInputChange(e);
          }
          handleInputChange(e);
        }}
        isDisabled={FieldProps.isDisabled}
        styles={{
          control: (styles, { isFocused }) => ({
            ...styles,
            fontSize: '14px',
            borderWidth: '1.5px',
            borderColor: isFocused
              ? colors.primary[800]
              : FieldProps?.isError
              ? ' #E53E3E'
              : '#E7E9ED',
            borderRadius: '4px',
            cursor: 'pointer',
            boxShadow: 'none',
            '&:active': {
              borderColor: colors.primary[800],
              outlineWidth: 2,
            },
            '&:hover': {
              ...styles[':hover'],
            },
            '& ::-webkit-scrollbar': {
              display: 'none',
            },
          }),
          option: (styles, { isSelected, isFocused, isDisabled }) => ({
            ...styles,
            background: isSelected
              ? colors.primary[800]
              : isFocused
              ? colors.primary[100]
              : styles.color,
            ':active': {
              ...styles[':active'],
              backgroundColor: !isDisabled
                ? isSelected
                  ? styles.color
                  : colors.primary[200]
                : undefined,
            },
            cursor: isDisabled ? 'not-allowed' : 'default',
          }),
          valueContainer: (styles: any) => ({
            ...styles,
            maxHeight: '33px',
            overflowY: 'scroll',
          }),
          dropdownIndicator: (styles: any) => ({
            ...styles,
            color: '#676767',
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
          placeholder: (styles: any) => ({
            ...styles,
            fontSize: '14px',
            color: '#9B9B9B',
          }),
        }}
      />
      {isOpen && (
        <JobsAddressModal
          isOpen={isOpen}
          onClose={onClose}
          addressData={addressData}
        />
      )}
    </>
  );
}
